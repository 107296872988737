/**
 * Do not edit directly
 * Generated on Thu, 13 Feb 2025 05:07:11 GMT
 */

:root {
  --brand-color-background: 252 252 255;
  --brand-color-background-secondary: 230 230 230;
  --brand-color-background-disabled: 210 210 210;
  --brand-color-border: 25 25 25;
  --brand-color-text: 0 0 5;
  --brand-color-text-secondary: 25 25 25;
  --brand-color-text-disabled: 150 150 150;
  --brand-color-destructive: 229 115 115;
  --brand-color-error: 229 115 115;
  --brand-color-success: 102 187 106;
  --brand-color-warning: 255 164 0;
  --brand-size-text: 12pt;
  --brand-size-component-min-height: 48px;
  --brand-size-component-min-width: 48px;
  --brand-size-component-gutter: 8px;
}
